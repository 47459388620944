<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

/*-- Import assets --*/
import {useBreakpoint} from "@/stores/Breakpoints";
import {Gender} from "@/enums/Gender";
import ProfileNavbarItem from "@/components/profile/ProfileNavbarItem.vue";
import {ItemType} from "@/enums/ItemType";
import {Style} from "@/enums/Style";
import {useRoute, useRouter} from "vue-router";
import {ItemStatus} from "@/enums/ItemStatus";
import useSearchService from "@/services/SearchService";
import {ItemCategory} from "@/enums/ItemCategory";

const route = useRoute();
const router = useRouter();

/* i18n */
const { t } = useI18n();

/*-- Services --*/
const searchService = useSearchService();

/*-- Stores --*/
const breakpoint = useBreakpoint();

onMounted(() => {
  initGenders();
});

const gendersRef = ref<Gender[]>([]);
const typesRef = ref<ItemType[]>([]);
const categoriesRef = ref<ItemCategory[]>([]);
const stylesRef = ref<Style[]>([]);
const categoriesByTypeRef = ref<{ [key: ItemType]: ItemCategory[] }>({});
const stylesByTypeRef = ref<{ [key: ItemType]: Style[] }>({});

const selectedGender = ref<Gender | null>(null);
const selectedType = ref<ItemType | null>(null);

const username = computed(() => route.params.username);
const genders = computed(() => gendersRef.value);
const types = computed(() => typesRef.value);
const categories = computed(() => categoriesRef.value);
const styles = computed(() => stylesRef.value);
const categoriesByType = computed(() => categoriesByTypeRef.value);
const stylesByType = computed(() => stylesByTypeRef.value);

const isGenderSelected = computed(() => selectedGender.value && !selectedType.value);
const isTypeSelected = computed(() => selectedGender.value && selectedType.value);

const getGenderGroup = (gender: Gender): Gender[] => {
  if (Gender.adult.includes(gender)) {
    return [gender, Gender.UNISEX];
  }
  if (Gender.child.includes(gender)) {
    return Gender.child;
  }
}

const initGenders = async () => {
  const searchParameters = {
    q: '*',
    query_fields: 'gender',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE,
    facet_by: 'gender',
  };

  await searchService.search('items', searchParameters).then(response => {
    const genders = response.facet_counts[0].counts.map((count: any) => Gender.fromString(count.value));
    if (genders.some(g => Gender.adult.includes(g))) {
      gendersRef.value.push(...Gender.adult);
    }
    if (genders.some(g => Gender.child.includes(g))) {
      gendersRef.value.push(...Gender.child);
    }
    // Remove Unisex, no need to show it
    gendersRef.value = gendersRef.value.filter(g => g !== Gender.UNISEX);
  });
}


// Select gender and load types
const selectGender = async (event: MouseEvent, gender: Gender | null) => {
  event.stopPropagation();
  console.log("selectGender", gender);

  selectedGender.value = gender;
  if (selectedGender.value === null) {
    selectedType.value = null;
    return;
  }

  await initTypes(selectedGender.value);
  // For Desktop
  await initCategoriesByType(selectedGender.value, typesRef.value);
  await initStylesByType(selectedGender.value, typesRef.value);
}

const initTypes = async (gender: Gender) => {
  const searchParameters = {
    q: '*',
    query_fields: 'type',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE
      + ' && gender:[' + getGenderGroup(gender) + ']',
    facet_by: 'type',
  }

  await searchService.search('items', searchParameters).then(response => {
    typesRef.value = response.facet_counts[0].counts.map((count: any) => ItemType.fromString(count.value)).sort();
  });
}

const initCategoriesByType = async (gender: Gender, types: ItemType[]) => {
  for (const type of types) {
    const searchParameters = {
      q: '*',
      query_fields: 'category',
      filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE
          + ' && gender:[' + getGenderGroup(gender) + ']'
          + ' && type:' + type,
      facet_by: 'category',
    }
    await searchService.search('items', searchParameters).then(response => {
      categoriesByTypeRef.value[type] = response.facet_counts[0].counts.map((count: any) => ItemCategory.fromString(count.value)).sort();
    });
  }
}

const initStylesByType = async (gender: Gender, types: ItemType[]) => {
  for (const type of types) {
    const searchParameters = {
      q: '*',
      query_fields: 'styles',
      filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE
          + ' && gender:[' + getGenderGroup(gender) + ']'
          + ' && type:' + type,
      facet_by: 'styles',
    }
    await searchService.search('items', searchParameters).then(response => {
      stylesByTypeRef.value[type] = response.facet_counts[0].counts.map((count: any) => Style.fromString(count.value)).sort();
    });
  }
}

// Select type and load categories
const selectType = async (event: MouseEvent, type: ItemType | null) => {
  event.stopPropagation();

  selectedType.value = type;
  if (selectedType.value === null) {
    selectedType.value = null;
    return;
  }

  await initCategories(selectedGender.value, selectedType.value);
  await initStyles(selectedGender.value, selectedType.value);
}

const initCategories = async (gender: Gender, type: ItemType) => {
  const searchParameters = {
    q: '*',
    query_fields: 'category',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE
      + ' && gender:[' + getGenderGroup(gender) + ']'
      + ' && type:' + type,
    facet_by: 'category',
  }
  await searchService.search('items', searchParameters).then(response => {
    categoriesRef.value = response.facet_counts[0].counts.map((count: any) => ItemCategory.fromString(count.value)).sort();
  });
}

const initStyles = async (gender: Gender, type: ItemType) => {
  const searchParameters = {
    q: '*',
    query_fields: 'styles',
    filter_by: 'username:' + username.value + ' && status:' + ItemStatus.AVAILABLE
      + ' && gender:[' + getGenderGroup(gender) + ']'
      + ' && type:' + type,
    facet_by: 'styles',
  }
  await searchService.search('items', searchParameters).then(response => {
    stylesRef.value = response.facet_counts[0].counts.map((count: any) => Style.fromString(count.value)).sort();
    console.log(stylesRef.value);
  });
}

const navigate = (gender: Gender, type: ItemType, category: ItemCategory, style: Style) => {
  router.push({
    name: 'profile-page',
    params: {
      gender: gender,
      type: type,
    },
    query: {
      category: category || undefined,
      style: style || undefined,
    }
  }).then(() => {
    location.reload();
  });
}

const getSubMenuHeight = computed(() => {
  if (isGenderSelected.value === true) {
    return {
      height: 'fit-content',
    };
  } else {
    return { height: 0 }; // Default height when conditions are not met
  }
});

// Dropdown visibility
const isDropdownOpen = ref(false);
const toggleDropdownVisibility = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
}
const closeDropdown = () => {
  isDropdownOpen.value = false;
}
const openDropdown = () => {
  isDropdownOpen.value = true;
}
</script>

<template>
  <header class="fixed top-0 left-0 right-0 bg-BGLight border-b-[1.5px] border-ltBorder z-[10]">
    <nav
      class="relative w-[100%] flex py-[.75rem] px-[1rem] z-[10] sm:h-[61px] sm:py-0 lg:px-[2rem] xxl:w-[1440px] xxl:mx-auto xxxl:px-0 xxxxl:w-[1920px]">
      <div id="nav-items-dropdown" class="w-[100%] flex items-center sm:w-fit">
        <!-- Hamburger -->
        <div @click="toggleDropdownVisibility" class="hamburger-icon" v-if="breakpoint.getWidth < 560">
          <span class="hamburger-icon-line hamburger-icon-line-top"></span>
          <span class="hamburger-icon-line hamburger-icon-line-middle"></span>
          <span class="hamburger-icon-line hamburger-icon-line-bottom"></span>
        </div>
        <!-- Nav Items -->
        <div
          class="w-[100%] absolute top-0 left-0 right-0 bottom-0 pb-[1rem] bg-BGLight z-[11] sm:py-0 sm:px-[1rem] lg:px-[2rem] xxxl:px-0"
          :class="breakpoint.getWidth >= 560 || isDropdownOpen
            ? 'menu-dropdown-open'
            : 'menu-dropdown-close'
            ">
          <!-- Action bar -->
          <div
            class="h-[64px] w-full flex items-center py-[.75rem] px-[1.25rem] bg-BGLight border-b-[1.5px] border-ltBorder"
            v-if="breakpoint.getWidth < 560">
            <!-- Breadcrumbs -->
            <div v-if="isGenderSelected" @click="selectGender($event, null)"
              class="breadcrumb flex gap-[.5rem] ml-[-.125rem] cursor-pointer">
              <i
                class="breadcrumb-icon ri-arrow-left-s-line text-[22px] h-[22px] flex justify-center items-center text-BGDark translate-y-[.5px]"></i>
              <span class="font-medium">{{ t('misc.all') }}</span>
            </div>
            <div v-else-if="isTypeSelected" @click="selectType($event, null)"
              class="breadcrumb flex gap-[.5rem] ml-[-.125rem] cursor-pointer">
              <i
                class="breadcrumb-icon ri-arrow-left-s-line text-[22px] h-[22px] flex justify-center items-center text-BGDark translate-y-[.5px]"></i>
              <span class="font-medium">{{ t(`gender.${selectedGender}`) }}</span>
            </div>
            <!-- Close Button -->
            <button @click="toggleDropdownVisibility"
              class="relative h-[36px] w-[36px] flex justify-center items-center ml-auto bg-BGLight border-[1px] border-TextSemiDark rounded-full">
              <i
                class="ri-close-line text-[22px] h-[22px] flex justify-center items-center text-BGDark translate-y-[.5px]"></i>
            </button>
          </div>
          <!-- Mobile Navbar Items Container -->
          <div v-if="breakpoint.getWidth < 560">
            <!-- Genders -->
            <div v-if="!selectedGender"
              class="flex flex-col gap-[1rem] py-[1.5rem] px-[1.5rem] sm:max-w-fit sm:flex-row sm:gap-0 sm:p-0">
              <ProfileNavbarItem v-for="gender in genders" @click="selectGender($event, gender)">
                <span class="text-[1.25rem] font-medium">{{ t(`gender.${gender}`) }}</span>
                <i
                  class="nav-item-icon ri-arrow-right-s-line text-[24px] h-[24px] flex justify-center items-center translate-y-[-1px] sm:hidden"></i>
              </ProfileNavbarItem>
            </div>
            <div v-if="isGenderSelected"
              class="flex flex-col gap-[1rem] py-[1.5rem] px-[1.5rem] sm:max-w-fit sm:flex-row sm:gap-0 sm:p-0">
              <p class="mb-[.5rem] text-[1.25rem] font-medium">{{ t(`gender.${selectedGender}`) }}</p>
              <ProfileNavbarItem v-for="type in types" @click="selectType($event, type)">
                <span class="text-[1rem] text-TextSemiDark">{{ t(`item_type.${type}`, 2) }}</span>
                <i
                  class="nav-item-icon ri-arrow-right-s-line text-[24px] h-[24px] flex justify-center items-center translate-y-[-1px] sm:hidden"></i>
              </ProfileNavbarItem>
            </div>
            <div v-if="isTypeSelected"
              class="flex flex-col gap-[1rem] py-[1.5rem] px-[1.5rem] sm:max-w-fit sm:flex-row sm:gap-0 sm:p-0">
              <p class="mb-[.5rem] text-[1.25rem] font-medium">{{ t(`item_type.${selectedType}`, 2) }}</p>
              <!-- All -->
              <ProfileNavbarItem class="ml-[.5rem]" @click="navigate(selectedGender, selectedType, null, null)">
                <span class="text-[1rem] text-TextSemiDark">{{ t('misc.all') }} {{ t(`item_type.${selectedType}`, 2)
                  }}</span>
              </ProfileNavbarItem>

              <!-- Categories -->
              <ProfileNavbarItem class="mt-[1rem]">
                <span class="text-[1rem] font-medium">{{ t(`item_category._name`, 2) }}</span>
              </ProfileNavbarItem>
              <ProfileNavbarItem v-for="category in categories" @click="navigate(selectedGender, selectedType, category, null)"
                class="ml-[.5rem]">
                <span class="text-[1rem] text-TextSemiDark">{{ t(`item_category.${category}`, 2) }}</span>
              </ProfileNavbarItem>

              <!-- Styles -->
              <ProfileNavbarItem v-if="styles.length > 0" class="mt-[1rem]">
                <span class="text-[1rem] font-medium">{{ t(`style._name`, 2) }}</span>
              </ProfileNavbarItem>
              <ProfileNavbarItem v-for="style in styles" @click="navigate(selectedGender, selectedType, null, style)"
                                 class="ml-[.5rem]">
                <span class="text-[1rem] text-TextSemiDark">{{ t(`style.${style}`) }}</span>
              </ProfileNavbarItem>
            </div>
          </div>
          <!-- Desktop Navbar Items Container -->
          <div v-else class="">
            <!-- Genders -->
            <div class="flex flex-row gap-[1.5rem]">
              <button v-for="gender in genders" class="nav-item flex justify-between items-center sm:py-[1rem]"
                @mouseenter="selectGender($event, gender)" @click="navigate(gender, 'all', null, null)">
                <span class="text-[1.125rem] font-medium border-b-[2px] translate-y-[1px] duration-[.2s] ease-in-out"
                  :class="selectedGender === gender ? 'border-BGDark' : 'border-transparent'">{{ t(`gender.${gender}`)
                  }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- Desktop Navbar Items -->
    <div v-if="breakpoint.getWidth >= 560" @mouseleave="selectGender($event, null)"
      class="overflow-hidden w-[100%] absolute top-[61px] left-0 bg-BGLight border-b-[1.5px] border-ltBorder duration-[.375s] ease-in-out"
      :style="getSubMenuHeight">
      <div class="px-[1rem] lg:px-[2rem] xxl:w-[1440px] xxl:mx-auto xxxl:px-0 xxxxl:w-[1920px]">
        <div class="flex gap-[1rem] mt-[1rem] mb-[2rem]">
          <div v-for="type in types" class="w-[200px] flex flex-col gap-[1rem]">
            <p class="font-medium">{{ t(`item_type.${type}`, 2) }}</p>
            <!-- All -->
            <ProfileNavbarItem class="ml-[.5rem] sm:cursor-pointer" @click="navigate(selectedGender, type, null, null)">
              <span class="text-[.875rem] text-TextSemiDark">{{ t('misc.all') }} {{ t(`item_type.${type}`, 2)
                }}</span>
            </ProfileNavbarItem>

            <!-- Categories -->
            <ProfileNavbarItem>
              <span class="text-[.875rem] font-medium">{{ t(`item_category._name`, 2) }}</span>
            </ProfileNavbarItem>
            <ProfileNavbarItem v-for="category in categoriesByType[type]" @click="navigate(selectedGender, type, category, null)"
              class="ml-[.5rem] sm:cursor-pointer">
              <span class="text-[.875rem] text-TextSemiDark">{{ t(`item_category.${category}`, 2) }}</span>
            </ProfileNavbarItem>

            <!-- Styles -->
            <ProfileNavbarItem v-if="stylesByType[type] && stylesByType[type].length > 0">
              <span class="text-[.875rem] font-medium">{{ t(`style._name`, 2) }}</span>
            </ProfileNavbarItem>
            <ProfileNavbarItem v-for="style in stylesByType[type]" @click="navigate(selectedGender, type, null, style)"
                               class="ml-[.5rem] sm:cursor-pointer">
              <span class="text-[.875rem] text-TextSemiDark">{{ t(`style.${style}`) }}</span>
            </ProfileNavbarItem>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<style scoped>
/*-- Menu --*/
.menu-dropdown-open {
  min-height: 100vh;
  transform: translateX(0);
  visibility: visible;
  overflow-y: auto;
  transition: all 0.2s ease-in-out;
}

.menu-dropdown-close {
  min-height: 100vh;
  transform: translateX(-100%);
  visibility: hidden;
  overflow-y: hidden;
  transition: all 0.2s ease-in-out;
}

/* Menu Icon */
.hamburger-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  width: 40px;
  height: 40px;
  border-radius: 60em;
  background-color: var(--BGNormal);
  position: relative;
  z-index: 10;
  cursor: pointer;
}

.hamburger-icon-line {
  height: 2px;
  width: 18px;
  border-radius: 60em;
  display: block;
  background-color: var(--TextDark);
  cursor: pointer;
  -webkit-transition: background-color 0.2s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out,
    -webkit-transform 0.2s ease-in-out;
}

.hamburger-icon-line-top,
.hamburger-icon-line-middle,
.hamburger-icon-line-bottom {
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  -ms-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}

.nav-item .nav-item-icon {
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
}

.breadcrumb-icon {
  transform: translateX(0);
  transition: transform 0.2s ease-in-out;
}

/* Hover isn't visible on touchscreen devices */
@media (hover: hover) {
  .hamburger-icon:hover .hamburger-icon-line-top {
    transform: translateY(-1px);
  }

  .hamburger-icon:hover .hamburger-icon-line-bottom {
    transform: translateY(1px);
  }

  .nav-item:hover .nav-item-icon {
    transform: translateX(4px);
  }

  .breadcrumb:hover .breadcrumb-icon {
    transform: translateX(-2px);
    transition: transform 0.2s ease-in-out;
  }
}

@media screen and (min-width: 560px) {
  .menu-dropdown-open {
    min-height: auto;
    overflow-y: visible;
  }
}
</style>
