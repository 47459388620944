import {Item} from "@/types/Item";
import {ItemType} from "@/enums/ItemType";
import {FirebaseTimestampToDate} from "@/utils/firebaseTimestampToDate";

export class Top extends Item {
    //TODO: Can these be nullable?
    private length: { [key: string]: string } | null; // {(CM/IN: value)}
    private chest: { [key: string]: string } | null; // {(CM/IN: value)}
    private arm: { [key: string]: string } | null; // {(CM/IN: value)}

    constructor(props: any) {
        props.type = ItemType.TOP;
        super(props);

        this.length = props.length || null;
        this.chest = props.chest || null;
        this.arm = props.arm || null;
    }

    static fromDocument(doc: any) {
        const data = doc.data();
        const top = new Top({
            id: doc.id,
            ...data,
            createdAt: FirebaseTimestampToDate(data.createdAt),
            droppedAt: data.droppedAt ? FirebaseTimestampToDate(data.droppedAt) : null,
        });
        return top;
    }

    public toDocument() {
        const doc = super.toDocument();
        //doc.colorway = this.colorway;
        //doc.modelNumber = this.modelNumber;
        doc.length = this.length;
        doc.chest = this.chest;
        doc.arm = this.arm;
        return doc;
    }

    public copy() {
        const doc = this.toDocument();
        doc.id = this.id;
        return new Top(doc);
    }

    static base(): Top {
        const base = Item.base();
        return new Top({
            ...base.toDocument(),
            type: ItemType.TOP,
            //colorway: null,
            //modelNumber: null,
            length: {},
            chest: {},
            arm: {},
        });
    }

    get Length(): { [p: string]: string } { return this.length; }
    set Length(value: { [p: string]: string }) { this.length = value; }

    get Chest(): { [p: string]: string } { return this.chest; }
    set Chest(value: { [p: string]: string }) { this.chest = value; }

    get Arm(): { [p: string]: string } { return this.arm; }
    set Arm(value: { [p: string]: string }) { this.arm = value; }
}