import {ItemCategory} from "@/enums/ItemCategory";

export enum ItemType {
    SHOE = "shoe",
    TOP = "top",
}

export namespace ItemType {
    export const keys = ["shoe", "top"];
    export const values = [ItemType.SHOE, ItemType.TOP];

    export function fromString(itemType: string): ItemType {
        switch (itemType) {
            case "shoe":
                return ItemType.SHOE;
            case "top":
                return ItemType.TOP;
            default:
                throw new Error('Invalid ItemType');
        }
    }

    export function getCategories(itemType: ItemType): ItemCategory[] {
        switch (itemType) {
            case ItemType.SHOE:
                return [ItemCategory.SNEAKER, ItemCategory.RUNNING_SHOE, ItemCategory.TRAINING_SHOE, ItemCategory.BASKETBALL_SHOE, ItemCategory.FOOTBALL_SHOE, ItemCategory.SANDAL, ItemCategory.SLIDE, ItemCategory.BOOT];
            case ItemType.TOP:
                return [ItemCategory.T_SHIRT, ItemCategory.SHIRT, ItemCategory.SWEATER, ItemCategory.SWEATSHIRT, ItemCategory.HOODIE, ItemCategory.JACKET, ItemCategory.COAT, ItemCategory.VEST, ItemCategory.JERSEY];
            default:
                throw new Error('Invalid ItemType');
        }
    }
}
