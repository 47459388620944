//Firebase
import {db} from '@/firebase/firebaseServices.js'
import {
    collection,
    addDoc,
    doc,
    updateDoc,
    deleteDoc,
    getDoc,
    getCountFromServer,
    query,
    where,
    writeBatch,
    getDocs,
    limit
} from 'firebase/firestore';
// Other
import {Item} from '@/types/Item';
import {Shoe} from "@/types/Shoe";
import {ItemType} from "@/enums/ItemType";
import {ItemFactory} from "@/factory/ItemFactory";
import type {Privacy} from "@/enums/Privacy";
import {Top} from "@/types/Top";


class ItemService {
    async add(item: Item): Promise<Item> {
        const itemsCollection = collection(db, 'items');
        const itemAsDocument = item.toDocument();
        itemAsDocument.createdAt = new Date(); // Set the createdAt field to the current date
        const itemDoc = await addDoc(itemsCollection, itemAsDocument);
        console.log("Added item", itemDoc.id);
        return await this.getById(itemDoc.id);
    }

    async getById(itemId: string): Promise<Item> {
        const itemsCollection = collection(db, 'items');
        const itemDoc = await getDoc(doc(itemsCollection, itemId));
        return ItemFactory.fromDocument(itemDoc);
    }

    /* Items are get by searchService now
    async getAll(): Promise<Item[]> {
        const itemsCollection = collection(db, 'items');
        const itemsSnapshot = await getDocs(itemsCollection);
        return itemsSnapshot.docs.map(doc => {
            switch (doc.data().type) {
                case ItemType.SHOE:
                    return Shoe.fromDocument(doc);
                default:
                    throw new Error('Unknown item type' + doc.data().type);
            }
        });
    }
     */
    async getItemsOfUser(userId: string): Promise<Item[]> {
        const itemsCollection = collection(db, 'items');
        const itemsQuery = query(itemsCollection, where('userId', '==', userId));
        const itemsSnapshot = await getDocs(itemsQuery);
        return itemsSnapshot.docs.map(doc => {
            switch (doc.data().type) {
                case ItemType.SHOE:
                    return Shoe.fromDocument(doc);
                case ItemType.TOP:
                    return Top.fromDocument(doc);
                default:
                    throw new Error('Unknown item type' + doc.data().type);
            }
        });
    }

    async getByDropId(dropId: string, count?: number): Promise<Item[]> {
        const itemsCollection = collection(db, 'items');
        const itemsQuery = count
            ? query(itemsCollection, where('dropId', '==', dropId), limit(count))
            : query(itemsCollection, where('dropId', '==', dropId));
        const itemsSnapshot = await getDocs(itemsQuery);
        return itemsSnapshot.docs.map(doc => ItemFactory.fromDocument(doc));
    }

    async getItemCountOfUser(userId: string): Promise<number> {
        const itemsCollection = collection(db, 'items');
        const itemsQuery = query(itemsCollection, where('userId', '==', userId));
        return (await getCountFromServer(itemsQuery)).data().count;
    }

    async getFeaturedItemCountOfUser(userId: string): Promise<number> {
        const itemsCollection = collection(db, 'items');
        const itemsQuery = query(itemsCollection, where('userId', '==', userId), where('featured', '==', true));
        return (await getCountFromServer(itemsQuery)).data().count;
    }

    async edit(item: Item): Promise<Item> {
        const itemsCollection = collection(db, 'items');
        await updateDoc(doc(itemsCollection, item.Id), item.toDocument());
        return item;
    }

    async editAll(items: Item[]): Promise<Item[]> {
        const batch = writeBatch(db);
        const itemsCollection = collection(db, 'items');
        items.forEach(item => {
            batch.update(doc(itemsCollection, item.Id), item.toDocument());
        });
        await batch.commit();
        return items;
    }

    /* Sets the privacy of all items of a user, corresponding to the profile's privacy settings */
    async editAllPrivacy(userId: string, privacy: Privacy): Promise<void> {
        const itemsCollection = collection(db, 'items');
        const itemsQuery = query(itemsCollection, where('userId', '==', userId));
        return getDocs(itemsQuery).then(async snapshot => {
            let batch = writeBatch(db);
            let batchCount = 0;
            for (const doc of snapshot.docs) {
                batch.update(doc.ref, {privacy});
                batchCount++;
                if (batchCount >= 499) { // Maximum batch size is 500, commit if batch is full
                    await batch.commit();
                    batchCount = 0;
                    batch = writeBatch(db);
                }
            }
            if (batchCount > 0) { // Commit the last batch if it is not empty
                await batch.commit();
            }
        });
    }

    async delete(item: Item): Promise<void> {
        const itemsCollection = collection(db, 'items');
        await deleteDoc(doc(itemsCollection, item.Id));
    }
}

export default function useItemService() {
    return new ItemService();
}
