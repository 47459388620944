import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from "@/pages/LandingPage.vue";
import { useUserStore } from "@/stores/UserStore";
import { useItemStore } from "@/stores/ItemStore";
import { useProfileStore } from "@/stores/ProfileStore";
import ProfilePage from "@/pages/ProfilePage.vue";
import HubPage from "@/pages/HubPage.vue";
import InspectItemComponent from "@/components/profile/InspectItem.vue";
import { useDropStore } from "@/stores/DropStore";
import { useSubscriptionStore } from "@/stores/SubscriptionStore";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/for-sellers',
            name: 'landing',
            component: () => import('@/pages/LandingPage.vue'),
            meta: {
                title: 'otherlet. - Public Inventory for Fashion Resellers'
            }
        },
        {
            path: '/',
            name: 'hub',
            component: () => import('@/pages/HubPage.vue'),
            meta: {
                title: 'otherlet. - Empowering Trusted Resellers'
            },
        },
        {
            path: '/legal',
            name: 'legal',
            component: () => import('@/pages/LegalPage.vue'),
            redirect: 'legal/tos',
            meta: {
                title: 'Legal - otherlet.',
            },
            children: [
                {
                    path: 'tos',
                    name: 'tos',
                    component: () => import('@/components/legal/TermsOfService.vue'),
                    meta: {
                        title: 'Terms of Service - otherlet.',
                    }
                },
                {
                    path: 'privacy',
                    name: 'privacy',
                    component: () => import('@/components/legal/PrivacyPolicy.vue'),
                    meta: {
                        title: 'Privacy Policy - otherlet.',
                    }
                }
            ]
        },
        {
            path: '/get-started',
            name: 'get-started',
            component: () => import('@/pages/GetStartedPage.vue'),
            meta: {
                title: 'Get Started - otherlet.'
            }
        },
        {
            path: '/create-subscription',
            name: 'create-subscription',
            component: () => import('@/pages/CreateSubscriptionPage.vue'),
            beforeEnter: (to, from, next) => {
                if (to.query.success === 'true') {
                    next({ name: 'console' });
                } else {
                    next();
                }
            },
            meta: {
                requiresAuth: true,
                title: 'Create Subscription - otherlet.'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/LoginPage.vue'),
            meta: {
                title: 'Login - otherlet.',
                requiresAuth: true //Redirect to console if already logged in
            }
        },
        {
            path: '/console',
            name: 'console',
            component: () => import('@/pages/BaseConsole.vue'),
            meta: {
                title: 'Console - otherlet.',
                requiresAuth: true,
                requiresSubscription: true,
                requiresProfile: true,
            },
            redirect: 'console/items',
            children: [
                {
                    path: 'items',
                    name: 'items',
                    component: () => import('@/pages/ItemsPage.vue'),
                    meta: {
                        title: 'Items - otherlet.',
                        requiresDrops: true // For assigning items to drops
                    },
                },
                {
                    path: 'drops',
                    name: 'drops',
                    component: () => import('@/pages/DropsPage.vue'),
                    meta: {
                        title: 'Drops - otherlet.',
                        requiresDrops: true
                    },
                },
                {
                    //Settings
                    path: 'settings',
                    name: 'settings',
                    component: () => import('@/pages/SettingsPage.vue'),
                    meta: {
                        title: 'Settings - otherlet.',
                    },
                    redirect: 'settings/profile',
                    children: [
                        {
                            path: 'profile',
                            name: 'settings-profile',
                            component: () => import('@/components/settings/profile/ProfileSettings.vue'),
                            meta: {
                                title: 'Profile - otherlet.',
                            }
                        },
                        {
                            path: 'shopping',
                            name: 'settings-shopping',
                            component: () => import('@/components/settings/shopping/ShoppingSettings.vue'),
                            meta: {
                                title: 'Shopping - otherlet.',
                            },
                        },
                        {
                            path: 'subscription',
                            name: 'settings-subscription',
                            component: () => import('@/components/settings/subscription/SubscriptionSettings.vue'),
                            meta: {
                                title: 'Subscription - otherlet.',
                            }
                        }
                    ]
                }
            ]
        },
        {
            path: '/:username',
            name: 'profile',
            component: ProfilePage,
            meta: {
                title: 'Profile - otherlet.',
                requiresProfile: true
            },
            redirect: to => {
                const { username } = to.params;
                return `/${username}/all/shoe/page/1`; // Redirect to page 1 with the actual username
            },
            children: [
                {
                    path: ':gender/:type/page/:page',
                    name: 'profile-page',
                    component: ProfilePage,  // Assuming the ProfilePage component handles the pagination internally
                }
            ]
        },
        {
            path: '/:username/item/:itemId',
            name: 'inspect-item',
            component: () => import('@/components/profile/InspectItem.vue'),
            meta: {
                //title: 'Inspect Item - otherlet.', //HANDLED BY INSPECT ITEM COMPONENT
                requiresProfile: true
            }
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'not-found',
            component: () => import('@/pages/NotFoundPage.vue'),
            meta: {
                title: 'Page not found - otherlet.'
            },
            /* TODO catch the incorrect routes, cause current one throws and error */
        },
    ]
})

router.beforeEach(async (to, from, next) => {
    for (const [key, value] of Object.entries(to.meta)) {
        console.log(`[meta] ${key}: ${value}`)
    }

    if (to.meta.requiresAuth) {
        console.log('[meta] requiresAuth executing...');
        const userStore = useUserStore();

        if (!userStore.getUser) {
            try {
                //This will log in the user if possible, load the user doc and the shallow workspaces
                await userStore.initAuthState();
                console.log('User:', userStore.getUser);
            } catch (error) {
                console.error('Error binding auth state change:', error);
                if (to.name !== 'login') {
                    next({ name: 'login' }); //Redirect to login page
                    return;
                }
            }
        }
    }

    if (to.meta.requiresSubscription) { //Requires subscription
        console.log('[meta] requiresSubscription executing...');
        const userStore = useUserStore();
        if (!userStore.getUser) {
            console.error('User not found, cannot fetch subscription');
            return;
        }

        const subscriptionStore = useSubscriptionStore();
        if (!subscriptionStore.getSubscription) {
            await subscriptionStore.fetchSubscription(userStore.getUser!.Id);
            console.log('Subscription:', subscriptionStore.getSubscription);
        }
        if (!subscriptionStore.getSubscription) {
            next({ name: 'create-subscription' });
            return;
        }
    }

    if (to.meta.requiresProfile) {
        console.log('[meta] requiresProfile executing...');
        const userStore = useUserStore();

        // Authenticated user
        if (userStore.getUser) {
            const userId = userStore.getUser.Id;

            const profileStore = useProfileStore();
            if (!profileStore.getProfile || profileStore.getProfile.Id !== userId) {
                await profileStore.fetchById(userId);
                console.log('Profile:', profileStore.getProfile);
            }
        } else {
            //Non-authenticated user
            const username = to.params.username as string;
            console.log('Fetching profile by username:', username)
            const profileStore = useProfileStore();
            if (!profileStore.getProfile
                || profileStore.getProfile.Username !== username) {
                await profileStore.fetchByUsername(username);
                if (!profileStore.getProfile) {
                    console.error('Profile not found');
                    next({ name: 'not-found' });
                }
                console.log('Profile:', profileStore.getProfile);
            }
        }
    }

    if (to.meta.requiresDrops) {
        console.log('[meta] requiresDrops executing...');
        const userStore = useUserStore();
        const dropStore = useDropStore();
        if (dropStore.getDrops.length === 0 && userStore.getUser) {
            console.log('Fetching drops by user id:', userStore.getUser.Id)
            await dropStore.fetchDropsByUserId(userStore.getUser!.Id);
            console.log('Drops:', dropStore.getDrops);
        } else if (!userStore.getUser) {
            console.error('User not found, cannot fetch drops');
        }
    }

    next();
});

//Set title
router.afterEach((to, from) => {
    if (to.meta.title) document.title = to.meta.title as string;
});

export default router
