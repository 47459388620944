import {Item} from "@/types/Item";
import {ItemType} from "@/enums/ItemType";
import {BoxCondition} from "@/enums/BoxCondition";
import {FirebaseTimestampToDate} from "@/utils/firebaseTimestampToDate";
import {ItemStatus} from "@/enums/ItemStatus";
import {Condition} from "@/enums/Condition";
import {Privacy} from "@/enums/Privacy";
import {ItemCategory} from "@/enums/ItemCategory";

export class Shoe extends Item {
    // name -> called 'model' on UI
    private boxCondition: BoxCondition | null;


    constructor(props: any) {
        props.type = ItemType.SHOE;
        super(props);

        this.boxCondition = props.boxCondition || null;
    }

    static fromDocument(doc: any) {
        const data = doc.data();
        const shoe = new Shoe({
            id: doc.id,
            ...data,
            createdAt: FirebaseTimestampToDate(data.createdAt),
            droppedAt: data.droppedAt ? FirebaseTimestampToDate(data.droppedAt) : null,
        });
        return shoe;
    }

    public toDocument() {
        const doc = super.toDocument();
        doc.boxCondition = this.boxCondition;
        return doc;
    }

    public copy() {
        const doc = this.toDocument();
        doc.id = this.id;
        return new Shoe(doc);
    }

    static base(): Shoe {
        const base = Item.base();
        return new Shoe({
            ...base.toDocument(),
            type: ItemType.SHOE,
            boxCondition: BoxCondition.FULL, //TODO: Remove this / add placeholder
        });
    }

    // Getters & Setters
    get BoxCondition(): BoxCondition | null {
        return this.boxCondition;
    }

    set BoxCondition(value: BoxCondition | null) {
        this.boxCondition = value;
    }
}
