<script setup lang="ts">

</script>

<template>
  <button class="nav-item flex justify-between items-center cursor-pointer sm:cursor-default">
    <slot></slot>
  </button>
</template>

<style scoped>

</style>