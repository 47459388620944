import { ItemType } from "@/enums/ItemType";
import { ItemStatus } from "@/enums/ItemStatus";
import {Condition} from "@/enums/Condition";
import type {Privacy} from "@/enums/Privacy";
import type {Color} from "@/enums/Color";
import {ItemCategory} from "@/enums/ItemCategory";
import {Gender} from "@/enums/Gender";
import {Style} from "@/enums/Style";

export abstract class Item {
    protected id: string;
    // User info
    private userId: string;
    private username: string;
    private privacy: Privacy;

    private gender: Gender;
    private type: ItemType;
    private category: ItemCategory | null;
    private styles: Style[];
    private name: string;
    private brand: string;
    private colorway: string | null;
    private modelNumber: string | null;
    private colors: Color[];
    private sizes: { [key: string]: string }; // {sizeType: sizeValue}
    private condition: Condition;
    private additionalInformation: string | null;
    private photoUrls: string[]; // URLs
    private salePrice: number;
    private saleCurrency: string;
    private buyPrice: number | null;
    private buyCurrency: string | null;
    private retailPrice: number | null;
    private retailCurrency: string | null;
    protected quantity: number;
    protected status: ItemStatus;
    protected featured: boolean;
    // Metadata
    protected createdAt: Date;
    // Drop
    protected dropId: string | null;
    protected droppedAt: Date | null; // When the item was dropped (last)

    protected constructor(props: any) {
        this.id = props.id || '';

        this.userId = props.userId;
        this.username = props.username;
        this.privacy = props.privacy;

        this.gender = props.gender;
        this.type = props.type;
        this.category = props.category;
        this.styles = props.styles || [];
        this.name = props.name;
        this.brand = props.brand;
        this.colorway = props.colorway || null;
        this.modelNumber = props.modelNumber || null;
        this.colors = props.colors || [];
        this.sizes = props.sizes;
        this.condition = props.condition;
        this.additionalInformation = props.additionalInformation;
        this.photoUrls = props.photoUrls;
        this.salePrice = props.salePrice;
        this.saleCurrency = props.saleCurrency;
        this.buyPrice = props.buyPrice || null;
        this.buyCurrency = props.buyCurrency;
        this.retailPrice = props.retailPrice || null;
        this.retailCurrency = props.retailCurrency;
        this.quantity = props.quantity;
        this.status = props.status;
        this.featured = props.featured || false;
        this.createdAt = props.createdAt instanceof Date ? props.createdAt : new Date(props.createdAt * 1000); // Assuming it's a millisecond timestamp (from TypeSense)
        this.dropId = props.dropId || null;
        this.droppedAt = props.droppedAt == undefined ? null :
            props.droppedAt instanceof Date ? props.droppedAt : new Date(props.droppedAt * 1000); // Assuming it's a millisecond timestamp (from TypeSense)
    }

    static fromDocument(doc: any): any; //extends Item, abstract

    public toDocument(): Record<string, any> {
        return {
            userId: this.userId,
            username: this.username,
            privacy: this.privacy,

            gender: this.gender,
            type: this.type,
            category: this.category,
            styles: [...this.styles], // Copy the array to prevent mutation
            name: this.name,
            brand: this.brand,
            colorway: this.colorway,
            modelNumber: this.modelNumber,
            colors: [...this.colors], // Copy the array to prevent mutation
            sizes: {...this.sizes},
            condition: this.condition,
            additionalInformation: this.additionalInformation,
            photoUrls: [...this.photoUrls], // Copy the array to prevent mutation
            salePrice: this.salePrice,
            saleCurrency: this.saleCurrency,
            buyPrice: this.buyPrice,
            buyCurrency: this.buyCurrency,
            retailPrice: this.retailPrice,
            retailCurrency: this.retailCurrency,
            quantity: this.quantity,
            status: this.status,
            featured: this.featured,
            createdAt: this.createdAt,
            dropId: this.dropId || null,
            droppedAt: this.droppedAt || null,
        };
    }

    public copy(): Item {
        const doc = this.toDocument();
        doc.id = this.id;
        return new Item(doc);
    }

    public static base(): Item {
        const props = {
            userId: "",
            username: "",
            privacy: "",
            styles: [],
            name: "",
            brand: "",
            colorway: null,
            modelNumber: null,
            colors: [],
            additionalInformation: "",
            photoUrls: [],
            salePrice: null,
            saleCurrency: "HUF",
            buyPrice: null,
            buyCurrency: "HUF",
            retailPrice: null,
            retailCurrency: "HUF",
            quantity: 1,
            status: ItemStatus.AVAILABLE,
            sizes: {},
            condition: Condition.NEW_WITH_TAGS,
        };
        return new Item(props);
    }

    // Getters and Setters
    get Id(): string { return this.id; }
    set Id(id: string) { this.id = id; }

    get UserId(): string { return this.userId; }
    set UserId(userId: string) { this.userId = userId; }

    get Username(): string { return this.username; }
    set Username(username: string) { this.username = username; }

    get Privacy(): Privacy { return this.privacy; }
    set Privacy(privacy: Privacy) { this.privacy = privacy; }

    get Gender(): Gender { return this.gender; }
    set Gender(gender: Gender) { this.gender = gender; }

    get Type(): ItemType { return this.type; }
    set Type(type: ItemType) { this.type = type; }

    get Category(): ItemCategory | null { return this.category; }
    set Category(category: ItemCategory | null) { this.category = category; }

    get Styles(): Style[] { return this.styles; }
    set Styles(styles: Style[]) { this.styles = styles; }

    get Name(): string { return this.name; }
    set Name(name: string) { this.name = name; }

    get Brand(): string { return this.brand; }
    set Brand(brand: string) { this.brand = brand; }

    get Colorway(): string | null { return this.colorway; }

    set Colorway(value: string | null) { this.colorway = value; }

    get ModelNumber(): string | null { return this.modelNumber; }

    set ModelNumber(value: string | null) { this.modelNumber = value; }

    get Colors(): Color[] { return this.colors; }
    set Colors(colors: Color[]) { this.colors = colors; }

    get Sizes(): { [key: string]: string } { return this.sizes; }
    set Sizes(sizes: { [key: string]: string }) { this.sizes = sizes; }

    get Condition(): Condition { return this.condition; }
    set Condition(condition: Condition) { this.condition = condition; }

    get AdditionalInformation(): string | null { return this.additionalInformation; }
    set AdditionalInformation(additionalInformation: string | null) { this.additionalInformation = additionalInformation; }

    get PhotoUrls(): string[] { return this.photoUrls; }
    set PhotoUrls(photoUrls: string[]) { this.photoUrls = photoUrls; }

    get SalePrice(): number { return this.salePrice; }
    set SalePrice(salePrice: number) { this.salePrice = salePrice; }

    get SaleCurrency(): string { return this.saleCurrency; }
    set SaleCurrency(saleCurrency: string) { this.saleCurrency = saleCurrency; }

    get BuyPrice(): number | null { return this.buyPrice; }
    set BuyPrice(buyPrice: number | null) { this.buyPrice = buyPrice; }

    get BuyCurrency(): string | null { return this.buyCurrency; }
    set BuyCurrency(buyCurrency: string | null) { this.buyCurrency = buyCurrency; }

    get RetailPrice(): number | null { return this.retailPrice; }
    set RetailPrice(retailPrice: number | null) { this.retailPrice = retailPrice; }

    get RetailCurrency(): string | null { return this.retailCurrency; }
    set RetailCurrency(retailCurrency: string | null) { this.retailCurrency = retailCurrency; }

    get Quantity(): number { return this.quantity; }
    set Quantity(quantity: number) { this.quantity = quantity; }

    get Status(): ItemStatus { return this.status; }
    set Status(status: ItemStatus) { this.status = status; }

    get Featured(): boolean { return this.featured; }
    set Featured(featured: boolean) { this.featured = featured; }

    get CreatedAt(): Date { return this.createdAt; }
    set CreatedAt(createdAt: Date) { this.createdAt = createdAt; }

    get DropId(): string | null { return this.dropId; }
    set DropId(dropId: string | null) { this.dropId = dropId; }

    get DroppedAt(): Date | null { return this.droppedAt; }
    set DroppedAt(droppedAt: Date | null) { this.droppedAt = droppedAt; }
}
