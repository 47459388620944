export enum ItemCategory {
    // Shoe
    SNEAKER = "sneaker",
    RUNNING_SHOE = "running_shoe",
    TRAINING_SHOE = "training_shoe",
    BASKETBALL_SHOE = "basketball_shoe",
    FOOTBALL_SHOE = "football_shoe",
    SANDAL = "sandal",
    SLIDE = "slide",
    BOOT = "boot",
    // Top
    T_SHIRT = "t_shirt",
    SHIRT = "shirt",
    SWEATER = "sweater",
    SWEATSHIRT = "sweatshirt",
    HOODIE = "hoodie",
    JACKET = "jacket",
    COAT = "coat",
    VEST = "vest",
    JERSEY = "jersey",
}

export namespace ItemCategory {
    export const keys = ["sneaker", "running_shoe", "training_shoe", "basketball_shoe", "football_shoe", "sandal", "slide", "boot", "t_shirt", "shirt", "sweater", "sweatshirt", "hoodie", "jacket", "coat", "vest", "jersey"];

    export function fromString(category: string): ItemCategory {
        switch (category) {
            case "sneaker":
                return ItemCategory.SNEAKER;
            case "running_shoe":
                return ItemCategory.RUNNING_SHOE;
            case "training_shoe":
                return ItemCategory.TRAINING_SHOE;
            case "basketball_shoe":
                return ItemCategory.BASKETBALL_SHOE;
            case "football_shoe":
                return ItemCategory.FOOTBALL_SHOE;
            case "sandal":
                return ItemCategory.SANDAL;
            case "slide":
                return ItemCategory.SLIDE;
            case "boot":
                return ItemCategory.BOOT;
            case "t_shirt":
                return ItemCategory.T_SHIRT;
            case "shirt":
                return ItemCategory.SHIRT;
            case "sweater":
                return ItemCategory.SWEATER;
            case "sweatshirt":
                return ItemCategory.SWEATSHIRT;
            case "hoodie":
                return ItemCategory.HOODIE;
            case "jacket":
                return ItemCategory.JACKET;
            case "coat":
                return ItemCategory.COAT;
            case "vest":
                return ItemCategory.VEST;
            case "jersey":
                return ItemCategory.JERSEY;
            default:
                throw new Error('Invalid ItemCategory');
        }
    }
}
