import {ItemType} from "@/enums/ItemType";
import {Shoe} from "@/types/Shoe";
import {Top} from "@/types/Top";

export class ItemFactory {
    /* Firestore document */
    static fromDocument(doc: any) {
        const data = doc.data();
        switch (data.type) {
            case ItemType.SHOE:
                return Shoe.fromDocument(doc);
            case ItemType.TOP:
                return Top.fromDocument(doc);
            default:
                console.error('Unknown item type', data.type);
                throw new Error('Invalid ItemType');
        }
    }

    /* TypeSense search results */
    static fromHit(hit: any) {
        switch (hit.document.type) {
            case ItemType.SHOE:
                return new Shoe(hit.document);
            case ItemType.TOP:
                return new Top(hit.document);
            default:
                console.error('Unknown item type', hit.document.type);
                throw new Error('Invalid ItemType');
        }
    }

    static fromHits(hits: any[]) {
        return hits.map((hit: any) => {
            return this.fromHit(hit);
        });
    }

    static fromResult(result: any) {
        if (result.found) {
            return this.fromHits(result.hits);
        } else {
            return [];
        }
    }

    static fromGroupedResult(result: any) {
        if (result.found) {
            return result.grouped_hits.map((grouped_hits: any) => {
                return this.fromHits(grouped_hits.hits);
            });
        } else {
            return [];
        }
    }
}
